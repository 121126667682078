<template>
  <div class="swiper">
    <swiper :options="swiperOption" ref="aa">
      <!-- 轮播的图片 -->
      <swiper-slide v-for="(item, index) in list" :key="index"> <img :src="item.url"/></swiper-slide>
      <!-- 小圆点分页器 -->
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    list: {
      type: Array,
      default: [{ url: require('../../assets/images/hospital_intelligence_a1.png') }],
    },
  },
  data() {
    return {
      swiperList: [
        {
          url: require('../../assets/images/hospital_intelligence_a1.png'),
        },
        {
          url: require('../../assets/images/hospital_intelligence_a2.png'),
        },
        {
          url: require('../../assets/images/hospital_intelligence_a3.png'),
        },
      ],
      swiperOption: {
        loop: true, // 设置图片循环
        autoplay: {
          // 自动滑动
          delay: 3000, //1秒切换一次
          disableOnInteraction: false,
        }, //设置可自动播放
        pagination: {
          el: '.swiper-pagination', //分页器的类名
          clickable: true, //设置分页小圆点可手动点击
        },
      },
    };
  },
  created() {
  },
  methods: {
    init() {
      var mySwiper = new swiper('.swiper', {
        loop: true, // 设置图片循环
        autoplay: {
          // 自动滑动
          delay: 3000, //1秒切换一次
          disableOnInteraction: false,
        }, //设置可自动播放
        pagination: {
          el: '.swiper-pagination', //分页器的类名
          clickable: true, //设置分页小圆点可手动点击
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.swiper {
  width: 100%;
  // height: 2.2rem;
}
.swiper img {
  width: 100%;
  // height: 100%;
}
</style>
