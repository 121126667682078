<template>
  <div class="mobile-home">
    <div class="mobile-home-header">
      <img src="../../../assets/mobile_images/mo_banner3.png" alt="" />
      <!-- <div class="mobile-header-btn">进一步了解</div> -->
    </div>
    <mobileTab :tabs="tabs" @tabChange="onTabChange" ref="tabIndex"></mobileTab>
    <div class="mobile-grey-imgbox" v-show="currentIndex == 0">
      <img @click="getImg(zngzgImgList,index)" v-for="(item, index) in zngzgImgList" :key="index" :src="item" />
    </div>
    <div class="mobile-grey-imgbox" v-show="currentIndex == 1">
      <img @click="getImg(znhcgImgList,index)" v-for="(item, index) in znhcgImgList" :key="index" :src="item" />
    </div>
    <div class="mobile-grey-imgbox" v-show="currentIndex == 2">
      <img @click="getImg(znpsImgList,index)" v-for="(item, index) in znpsImgList" :key="index" :src="item" />
    </div>
    <div class="mobile-grey-imgbox" v-show="currentIndex == 3">
      <img @click="getImg(znssImgList,index)" v-for="(item, index) in znssImgList" :key="index" :src="item" />
    </div>
    <!-- 产品参数 -->
    <mobileTitle titleZh="产品参数" marginTopZh=".64rem" marginBottomZh=".34rem"></mobileTitle>
    <div class="table-box">
      <div class="table-list">
        <div class="table-list-left">类别</div>
        <div class="table-list-right">技术参数</div>
      </div>
      <div class="table-list">
        <div class="table-list-left">尺寸</div>
        <div class="table-list-right" v-if="currentIndex == 0">
          <p>主柜：<br />750mm（宽），600mm（深），2050mm（高）</p>
          <p>副柜：<br />1000mm（宽），600mm（深），2050mm（高）</p>
        </div>
        <div class="table-list-right" v-else>
          <p>主柜：<br />1000mm（宽），600mm（深），2000mm（高）</p>
          <p>副柜：<br />1000mm（宽），600mm（深），2000mm（高）</p>
        </div>
      </div>
      <div class="table-list">
        <div class="table-list-left">储存环境</div>
        <div class="table-list-right">
          <p>环境湿度：-10~+50℃</p>
          <p>相对湿度：10%~95%</p>
        </div>
      </div>
      <div class="table-list">
        <div class="table-list-left">柜体</div>
        <div class="table-list-right">
          <p>智能主柜：<br />主柜包括电脑控制单元和层式副柜单元</p>
          <p>智能副柜：<br />1、层式副柜：<br />盒装类，存储高度空间灵活配置</p>
          <p>2、导管副柜：<br />悬挂及立式存放导管、导丝类耗材</p>
        </div>
      </div>
      <div class="table-list">
        <div class="table-list-left">显示屏</div>
        <div class="table-list-right">
          <p>对角尺寸：17英寸</p>
          <p>触摸方式：可戴手套触摸式高清屏</p>
          <p>背光类型：LED背光</p>
          <p>显示面积：<br />304.128mm(H)*228.096mm(V)</p>
          <p>显示分辨率：1024*768px</p>
          <p>显示比例：4:3</p>
          <p>像素及尺寸：<br />0.297(H)*0.297mm（V）</p>
        </div>
      </div>
      <div class="table-list">
        <div class="table-list-left">身份识别</div>
        <div class="table-list-right">
          <p>支持指纹、刷卡授权系统操作权限</p>
        </div>
      </div>
      <div class="table-list">
        <div class="table-list-left">接口</div>
        <div class="table-list-right">
          <p>支持RJ45/LAN，USB，COM等数据接口</p>
        </div>
      </div>
      <div class="table-list">
        <div class="table-list-left">监控设备</div>
        <div class="table-list-right">
          <p>宽动态，超低照明，3D降噪，镜头与机身分离，方便摄像机装置，支持丰富的网络协议，支持micro SD卡存储</p>
        </div>
      </div>
      <div class="table-list">
        <div class="table-list-left">电子锁</div>
        <div class="table-list-right">
          <p>1、外形尺寸：<br />80mm（长）*57mm（宽）*13mm（厚）</p>
          <p>2、开锁瞬间通电时间150ms以内，上锁状态为断电状态无损耗，电压常规为DC12V或DC24V</p>
        </div>
      </div>
      <div class="table-list">
        <div class="table-list-left">内置光源灯</div>
        <div class="table-list-right">
          <p>柜门开启自动触发暖色光源</p>
        </div>
      </div>
      <div class="table-list">
        <div class="table-list-left">电源</div>
        <div class="table-list-right">
          <p>通用电源，输入220V、2A、50Hz</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mobileTitle from '@/components/title/mobile_title.vue'; //标题组件
import mobileTab from '@/components/tab/mobile_tab.vue'; //选项卡组件
export default {
  components: {
    mobileTitle,
    mobileTab,
  },
  data() {
    return {
      currentIndex: 0,
      tabs: [
        { title: '智能高值耗材柜' },
        { title: '智能普耗柜' },
        { title: '智能配送设备' },
        { title: '智能输送设备' },
      ],
      zngzgImgList: [
        require('../../../assets/mobile_images/mo_hispital_intelligence_zngzg1.png'),
        require('../../../assets/mobile_images/mo_hispital_intelligence_zngzg2.png'),
        require('../../../assets/mobile_images/mo_hispital_intelligence_zngzg3.png'),
      ],
      znhcgImgList: [
        require('../../../assets/mobile_images/mo_hispital_intelligence_znhcg1.png'),
        require('../../../assets/mobile_images/mo_hispital_intelligence_znhcg2.png'),
        require('../../../assets/mobile_images/mo_hispital_intelligence_znhcg3.png'),
      ],
      znpsImgList: [
        require('../../../assets/mobile_images/mo_hispital_intelligence_znps1.png'),
        require('../../../assets/mobile_images/mo_hispital_intelligence_znps2.png'),
        require('../../../assets/mobile_images/mo_hispital_intelligence_znps3.png'),
      ],
      znssImgList: [
        require('../../../assets/mobile_images/mo_hispital_intelligence_znss1.png'),
        require('../../../assets/mobile_images/mo_hispital_intelligence_znss2.png'),
        require('../../../assets/mobile_images/mo_hispital_intelligence_znss3.png'),
      ],
    };
  },
  mounted() {
    if (this.$route.query.index) {
      let index = Number(this.$route.query.index);
      this.currentIndex = index;
      this.$refs.tabIndex.currentIndex = index;
    }
  },
  methods: {
    onTabChange(item, index) {
      this.currentIndex = index;
      // this.$refs.tabs.currentIndex = index
      console.log(index);
    },
    getImg(imgList,index) {
      this.$ImagePreview({
        images: imgList,
        startPosition: index,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.mobile-home {
  margin: 0 auto;
  .mobile-home-header {
    position: relative;
    min-height: 5rem;
    img {
      width: 100%;
      vertical-align: bottom;
    }
    .mobile-header-btn {
      position: absolute;
      left: 50%;
      bottom: 1.8rem;
      transform: translateX(-50%);
      width: 2.4rem;
      height: 0.72rem;
      line-height: 0.72rem;
      font-size: 0.28rem;
      text-align: center;
      color: #fff;
      background: #4fa1a4;
      border-radius: 0.04rem;
    }
  }
  .mobile-grey-imgbox {
    padding: 0.4rem 0.4rem 0 0.4rem;
    background: #f7f7f7;
    box-sizing: border-box;
    img {
      width: 100%;
      margin-bottom: 0.4rem;
    }
  }
  .table-box {
    padding: 0 0.4rem;
    color: #3d3d3d;
    margin: auto;
    .table-list {
      display: flex;
      //   align-items: center;
      padding: 0.3rem 0;
      font-size: 0.26rem;
      line-height: 0.38rem;
      border-bottom: 1px solid #e6e6e6;
      &:first-child {
        border-bottom: none;
        background: #fafafa;
      }
      .table-list-left {
        width: 2.04rem;
        padding-left: 0.32rem;
        flex-shrink: 0;
        text-align: left;
        box-sizing: border-box;
      }
      .table-list-right p {
        text-align: left;
      }
    }
  }
}
</style>
