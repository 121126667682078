var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-home"},[_vm._m(0),_c('mobileTab',{ref:"tabIndex",attrs:{"tabs":_vm.tabs},on:{"tabChange":_vm.onTabChange}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex == 0),expression:"currentIndex == 0"}],staticClass:"mobile-grey-imgbox"},_vm._l((_vm.zngzgImgList),function(item,index){return _c('img',{key:index,attrs:{"src":item},on:{"click":function($event){return _vm.getImg(_vm.zngzgImgList,index)}}})}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex == 1),expression:"currentIndex == 1"}],staticClass:"mobile-grey-imgbox"},_vm._l((_vm.znhcgImgList),function(item,index){return _c('img',{key:index,attrs:{"src":item},on:{"click":function($event){return _vm.getImg(_vm.znhcgImgList,index)}}})}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex == 2),expression:"currentIndex == 2"}],staticClass:"mobile-grey-imgbox"},_vm._l((_vm.znpsImgList),function(item,index){return _c('img',{key:index,attrs:{"src":item},on:{"click":function($event){return _vm.getImg(_vm.znpsImgList,index)}}})}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex == 3),expression:"currentIndex == 3"}],staticClass:"mobile-grey-imgbox"},_vm._l((_vm.znssImgList),function(item,index){return _c('img',{key:index,attrs:{"src":item},on:{"click":function($event){return _vm.getImg(_vm.znssImgList,index)}}})}),0),_c('mobileTitle',{attrs:{"titleZh":"产品参数","marginTopZh":".64rem","marginBottomZh":".34rem"}}),_c('div',{staticClass:"table-box"},[_vm._m(1),_c('div',{staticClass:"table-list"},[_c('div',{staticClass:"table-list-left"},[_vm._v("尺寸")]),(_vm.currentIndex == 0)?_c('div',{staticClass:"table-list-right"},[_vm._m(2),_vm._m(3)]):_c('div',{staticClass:"table-list-right"},[_vm._m(4),_vm._m(5)])]),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-home-header"},[_c('img',{attrs:{"src":require("../../../assets/mobile_images/mo_banner3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-list"},[_c('div',{staticClass:"table-list-left"},[_vm._v("类别")]),_c('div',{staticClass:"table-list-right"},[_vm._v("技术参数")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("主柜："),_c('br'),_vm._v("750mm（宽），600mm（深），2050mm（高）")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("副柜："),_c('br'),_vm._v("1000mm（宽），600mm（深），2050mm（高）")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("主柜："),_c('br'),_vm._v("1000mm（宽），600mm（深），2000mm（高）")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("副柜："),_c('br'),_vm._v("1000mm（宽），600mm（深），2000mm（高）")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-list"},[_c('div',{staticClass:"table-list-left"},[_vm._v("储存环境")]),_c('div',{staticClass:"table-list-right"},[_c('p',[_vm._v("环境湿度：-10~+50℃")]),_c('p',[_vm._v("相对湿度：10%~95%")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-list"},[_c('div',{staticClass:"table-list-left"},[_vm._v("柜体")]),_c('div',{staticClass:"table-list-right"},[_c('p',[_vm._v("智能主柜："),_c('br'),_vm._v("主柜包括电脑控制单元和层式副柜单元")]),_c('p',[_vm._v("智能副柜："),_c('br'),_vm._v("1、层式副柜："),_c('br'),_vm._v("盒装类，存储高度空间灵活配置")]),_c('p',[_vm._v("2、导管副柜："),_c('br'),_vm._v("悬挂及立式存放导管、导丝类耗材")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-list"},[_c('div',{staticClass:"table-list-left"},[_vm._v("显示屏")]),_c('div',{staticClass:"table-list-right"},[_c('p',[_vm._v("对角尺寸：17英寸")]),_c('p',[_vm._v("触摸方式：可戴手套触摸式高清屏")]),_c('p',[_vm._v("背光类型：LED背光")]),_c('p',[_vm._v("显示面积："),_c('br'),_vm._v("304.128mm(H)*228.096mm(V)")]),_c('p',[_vm._v("显示分辨率：1024*768px")]),_c('p',[_vm._v("显示比例：4:3")]),_c('p',[_vm._v("像素及尺寸："),_c('br'),_vm._v("0.297(H)*0.297mm（V）")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-list"},[_c('div',{staticClass:"table-list-left"},[_vm._v("身份识别")]),_c('div',{staticClass:"table-list-right"},[_c('p',[_vm._v("支持指纹、刷卡授权系统操作权限")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-list"},[_c('div',{staticClass:"table-list-left"},[_vm._v("接口")]),_c('div',{staticClass:"table-list-right"},[_c('p',[_vm._v("支持RJ45/LAN，USB，COM等数据接口")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-list"},[_c('div',{staticClass:"table-list-left"},[_vm._v("监控设备")]),_c('div',{staticClass:"table-list-right"},[_c('p',[_vm._v("宽动态，超低照明，3D降噪，镜头与机身分离，方便摄像机装置，支持丰富的网络协议，支持micro SD卡存储")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-list"},[_c('div',{staticClass:"table-list-left"},[_vm._v("电子锁")]),_c('div',{staticClass:"table-list-right"},[_c('p',[_vm._v("1、外形尺寸："),_c('br'),_vm._v("80mm（长）*57mm（宽）*13mm（厚）")]),_c('p',[_vm._v("2、开锁瞬间通电时间150ms以内，上锁状态为断电状态无损耗，电压常规为DC12V或DC24V")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-list"},[_c('div',{staticClass:"table-list-left"},[_vm._v("内置光源灯")]),_c('div',{staticClass:"table-list-right"},[_c('p',[_vm._v("柜门开启自动触发暖色光源")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-list"},[_c('div',{staticClass:"table-list-left"},[_vm._v("电源")]),_c('div',{staticClass:"table-list-right"},[_c('p',[_vm._v("通用电源，输入220V、2A、50Hz")])])])
}]

export { render, staticRenderFns }