<template>
  <div class="home-block">
    <div class="home">
      <!-- 头部开始 -->
      <div class="home-header">
        <img class="w100f" src="../../assets/images/hospital_intelligence_bg.jpg" alt="" />
        <div class="bg-text">
          <div class="bg-text-left">
            <p class="font-title mb20">智能化设备</p>
            <p class="font-txt">
              多抽屉存放 <span class="font-txt-tag">|</span> 配送任务一键下达
              <span class="font-txt-tag">|</span> 身份认证
            </p>
            <p class="font-intro">
              以满足院内物资日常配送需求，实现普通耗材、药品、器械等日<br />常物资的运输智能化和无人化。
            </p>
            <!-- <div class="next-btn">进一步了解</div> -->
          </div>
          <!-- <div class="bg-text-right">
          <p class="font-one">Technology leads transform, Data drives serviceValue</p>
          <p class="font-two">Transparent Word</p>
        </div> -->
        </div>
      </div>
      <!-- 头部结束 -->
      <!-- 中间内容开始 -->
      <div class="main-contain">
        <div class="h100"></div>
        <Tab :tabs="tabs" @tabChange="onTabChange" ref="tabIndex"></Tab>
        <div class="h63"></div>
        <!-- <SwiperBox  :list="swiperList" ref="swipers"></SwiperBox> -->
        <div class="swiper-box">
          <!-- 轮播一 -->
          <swiper :options="swiperOption" v-show="currentIndex == 0">
            <swiper-slide v-for="(item, index) in swiperList1" :key="index"><img :src="item.url" /></swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
          <!-- 轮播二 -->
          <swiper :options="swiperOption" v-show="currentIndex == 1">
            <swiper-slide v-for="(item, index) in swiperList2" :key="index"><img :src="item.url" /></swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
          <!-- 轮播三 -->
          <swiper :options="swiperOption" v-show="currentIndex == 2">
            <swiper-slide v-for="(item, index) in swiperList3" :key="index"><img :src="item.url" /></swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
          <!-- 轮播四 -->
          <swiper :options="swiperOption" v-show="currentIndex == 3">
            <swiper-slide v-for="(item, index) in swiperList4" :key="index"><img :src="item.url" /></swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
          <!-- 左右按钮 -->
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
        <Title titleZh="产品参数" titleEn=""></Title>
        <!-- 参数介绍 -->
        <div class="table-box">
          <div class="table-list">
            <div class="table-list-left">类别</div>
            <div class="table-list-right">技术参数</div>
          </div>
          <div class="table-list">
            <div class="table-list-left">尺寸</div>
            <div class="table-list-right" v-if="currentIndex == 0">
              <p>主柜：750mm（宽），600mm（深），2050mm（高）</p>
              <p>副柜：1000mm（宽），600mm（深），2050mm（高）</p>
            </div>
            <div class="table-list-right" v-else>
              <p>主柜：1000mm（宽），600mm（深），2000mm（高）</p>
              <p>副柜：1000mm（宽），600mm（深），2000mm（高）</p>
            </div>
          </div>
          <div class="table-list">
            <div class="table-list-left">储存环境</div>
            <div class="table-list-right">
              <p>环境湿度：-10~+50℃</p>
              <p>相对湿度：10%~95%</p>
            </div>
          </div>
          <div class="table-list">
            <div class="table-list-left">柜体</div>
            <div class="table-list-right">
              <p>智能主柜：主柜包括电脑控制单元和层式副柜单元</p>
              <p>智能副柜：1、层式副柜：盒装类，存储高度空间灵活配置</p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2、导管副柜：悬挂及立式存放导管、导丝类耗材
              </p>
            </div>
          </div>
          <div class="table-list">
            <div class="table-list-left">显示屏</div>
            <div class="table-list-right">
              <p>对角尺寸：17英寸</p>
              <p>触摸方式：可戴手套触摸式高清屏</p>
              <p>背光类型：LED背光</p>
              <p>显示面积：304.128mm(H)*228.096mm(V)</p>
              <p>显示分辨率：1024*768px</p>
              <p>显示比例：4:3</p>
              <p>像素及尺寸：0.297(H)*0.297mm（V）</p>
            </div>
          </div>
          <div class="table-list">
            <div class="table-list-left">身份识别</div>
            <div class="table-list-right">
              <p>支持指纹、刷卡授权系统操作权限</p>
            </div>
          </div>
          <div class="table-list">
            <div class="table-list-left">接口</div>
            <div class="table-list-right">
              <p>支持RJ45/LAN，USB，COM等数据接口</p>
            </div>
          </div>
          <div class="table-list">
            <div class="table-list-left">监控设备</div>
            <div class="table-list-right">
              <p>宽动态，超低照明，3D降噪，镜头与机身分离，方便摄像机装置，支持丰富的网络协议，支持micro SD卡存储</p>
            </div>
          </div>
          <div class="table-list">
            <div class="table-list-left">电子锁</div>
            <div class="table-list-right">
              <p>1、外形尺寸：80mm（长）*57mm（宽）*13mm（厚）</p>
              <p>2、开锁瞬间通电时间150ms以内，上锁状态为断电状态无损耗，电压常规为DC12V或DC24V</p>
            </div>
          </div>
          <div class="table-list">
            <div class="table-list-left">内置光源灯</div>
            <div class="table-list-right">
              <p>柜门开启自动触发暖色光源</p>
            </div>
          </div>
          <div class="table-list">
            <div class="table-list-left">电源</div>
            <div class="table-list-right">
              <p>通用电源，输入220V、2A、50Hz</p>
            </div>
          </div>
        </div>
        <div class="h200"></div>
      </div>
    </div>
    <mobilePage></mobilePage>
  </div>
</template>
<script>
import mobilePage from '@/views/hospital/mobile/hospital_intelligence.vue'; //移动端页面
import Tab from '@/components/tab/tab.vue'; //选项卡组件
import Title from '@/components/title/title.vue'; //标题组件
import SwiperBox from '@/components/swiper/swiper.vue'; //轮播组件
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
export default {
  //   name: 'home',
  components: {
    Title,
    Tab,
    SwiperBox,
    swiper,
    swiperSlide,
    mobilePage,
  },
  data() {
    return {
      swiperOption: {
        loop: true, // 设置图片循环
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination', //分页器的类名
          clickable: true, //设置分页小圆点可手动点击
        },
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        },
        observer: true,
        observeParents: true,
        initialSlide: 0,
      },
      currentIndex: 0,
      tabs: [
        { title: '智能高值耗材柜' },
        { title: '智能普耗柜' },
        { title: '智能配送设备' },
        { title: '智能输送设备' },
      ],
      swiperList: [],
      swiperList1: [
        {
          url: require('../../assets/images/hospital_intelligence_a1.png'),
        },
        {
          url: require('../../assets/images/hospital_intelligence_a2.png'),
        },
        {
          url: require('../../assets/images/hospital_intelligence_a3.png'),
        },
      ],
      swiperList2: [
        {
          url: require('../../assets/images/hospital_intelligence_b1.png'),
        },
        {
          url: require('../../assets/images/hospital_intelligence_b2.png'),
        },
        {
          url: require('../../assets/images/hospital_intelligence_b3.png'),
        },
      ],
      swiperList3: [
        {
          url: require('../../assets/images/hospital_intelligence_c1.png'),
        },
        {
          url: require('../../assets/images/hospital_intelligence_c2.png'),
        },
        {
          url: require('../../assets/images/hospital_intelligence_c3.png'),
        },
        {
          url: require('../../assets/images/hospital_intelligence_c4.png'),
        },
      ],
      swiperList4: [
        {
          url: require('../../assets/images/hospital_intelligence_d1.png'),
        },
        {
          url: require('../../assets/images/hospital_intelligence_d2.png'),
        },
        {
          url: require('../../assets/images/hospital_intelligence_d3.png'),
        },
        {
          url: require('../../assets/images/hospital_intelligence_d4.png'),
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.index) {
      let index = Number(this.$route.query.index);
      this.currentIndex = index;
      this.$refs.tabIndex.currentIndex = index;
    }
  },
  created() {
    this.currentIndex = this.$route.query.index ? Number(this.$route.query.index) : 0;
    // if (index == 1) {
    //   this.swiperList = this.swiperList2;
    // } else if (index == 2) {
    //   this.swiperList = this.swiperList3;
    // } else if (index == 3) {
    //   this.swiperList = this.swiperList4;
    // } else {
    //   this.swiperList = this.swiperList1;
    // }
  },
  methods: {
    onTabChange(item, index) {
      this.currentIndex = index;
      // this.$refs.swipers.swiper.destroy(); //先销毁
      // this.swiperList = [];
      // if (index == 1) {
      //   this.swiperList = this.swiperList2;
      // } else if (index == 2) {
      //   this.swiperList = this.swiperList3;
      // } else if (index == 3) {
      //   this.swiperList = this.swiperList4;
      // } else {
      //   this.swiperList = this.swiperList1;
      // }
      // console.log(this.$refs.swipers)
      // // this.$refs.swipers.reLoop();
      // this.$refs.swipers.mountInstance(); //后在加载
    },
  },
};
</script>
<style lang="less" scoped>
.swiper-box {
  width: 100%;
  position: relative;
  img {
    width: 100%;
  }
  .swiper-button-prev {
    width: 68px;
    height: 68px;
    left: 0;
    background-size: 100% 100%;
    background-image: url('../../assets/images/swiper_left.png');
  }
  .swiper-button-next {
    width: 68px;
    height: 68px;
    right: 0;
    background-size: 100% 100%;
    background-image: url('../../assets/images/swiper_right.png');
  }
}

.home {
  //头部
  .home-header {
    position: relative;
    width: 100%;
    min-height: 450px;
    .bg-text {
      width: 1200px;
      // height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      .bg-text-left {
        text-align: left;
        .font-title {
          font-size: 45px;
          color: #4fa1a4;
        }
        .font-txt {
          font-size: 20px;
          color: #3d3d3d;
          .font-txt-tag {
            margin: 0 20px;
          }
        }
        .font-intro {
          font-size: 16px;
          color: #3d3d3d;
          text-align: left;
          line-height: 30px;
          margin-top: 40px;
        }
        .next-btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          background: #4fa1a4;
          opacity: 1;
          border-radius: 24px;
          text-align: center;
          cursor: pointer;
        }
      }
      .bg-text-right {
        font-family: Source Han Sans CN;
        text-align: right;
        .font-one {
          font-size: 30px;
        }
        .font-two {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
  //中间
  .main-contain {
    width: 1200px;
    .table-box {
      width: 1080px;
      color: #3d3d3d;
      margin: auto;
      .table-list {
        display: flex;
        align-items: center;
        padding: 14px 0;
        border-bottom: 1px solid #e6e6e6;
        &:first-child {
          border-bottom: none;
          background: #fafafa;
        }
        .table-list-left {
          width: 287px;
          padding-left: 40px;
          flex-shrink: 0;
          text-align: left;
        }
        .table-list-right p {
          font-size: 16px;
          text-align: left;
          line-height: 25px;
        }
      }
    }
  }
}
</style>
